import { FC } from 'react';
import Container from '../../components/footer/Container';
import classes from './index.module.scss'
import womanImage from '../../assets/womanBruh.png';
import zoom from '../../assets/icons/Zoom.png';
import googleMeet from '../../assets/icons/googleMeet.png';
import { Button, Checkbox, DatePicker, Form, Input, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';

import 'dayjs/locale/ru';
import { ToastContainer, toast } from 'react-toastify';
import { PUBLIC_API } from '../../constants/api';

const Index: FC = () => {
    const [form] = Form.useForm();
    const onFinish = async (values: any) => {
        const newValues = {...values}        
        delete newValues.politika
        const date = values?.date_of_visit?.format('YYYY-MM-DD HH:mm')
        const backData = {
            ...newValues,
            date_of_visit: date,
            type_of_appli: '3',
        }
        try {
            const res = await PUBLIC_API.post('partners/application/', backData)
            if (res.data.status === 'CREATED'){
                toast.success('Заявка отправлена')
            }
        } catch (error) {
            toast.error('Произошла ошибка')
            return;
        }
    }
    const onFinishFailed = (values: any) => {
        toast.error('Заполните поля!')
    }
    return (
       <>
        <Container className={classes.container}>
            <div className={classes.imageBlock}>
                <div>
                    <h1 className={classes.headTitle}>
                        ВИДЕОКОНСУЛЬТАЦИЯ
                        
                    </h1>
                    <div className={classes.items}>
                            <div className={classes.item}>1. Покажем по видеосвязи выбранные вами сувениры</div>
                            <div className={classes.item}>2. Проведём онлайн-экскурсию по шоу-руму</div>
                            <div className={classes.item}>3. Познакомим с новинками мерча и подарков под логотип</div>
                            <div className={classes.item}>4. Запишем видеообзор на понравившийся вам мерч</div>
                    </div>
                    <div className={classes.reducer02}>
                        <p>Форматы связи:</p>
                        <div className={classes.icons}>
                            <img src={zoom} alt=""/>
                            <img src={googleMeet} alt=""/>
                        </div>
                    </div>
                </div>
                <img src={womanImage} alt=""/>
            </div>

            <div className={classes.formWrapper}>
                <h1 className={classes.headTitle}>ПОЛУЧИТЬ КОНСУЛЬТАЦИЮ</h1>
                <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className={classes.form}
                >
                    <Form.Item
                    rules={[{ required: true, message: 'Введите имя!' }]}
                    name={'name'}
                    >
                        <Input className={classes.input} placeholder={'Имя'}/>
                    </Form.Item>
                    <Form.Item
                    rules={[{ required: true, message: 'Введите название компании!' }]}


                    name={'company'}
                    >
                        <Input className={classes.input} placeholder={'Компания'}/>
                    </Form.Item>

                    <Form.Item
                    rules={[{ required: true, message: 'Введите эмейл!' }]}


                    name={'email'}
                    >
                        <Input className={classes.input} placeholder={'Корпоративный e-mail'}/>
                    </Form.Item>
                    <Form.Item
                    rules={[{ required: true, message: 'Выбрите тип связи!' }]}


                    name={'type_of_video'}
                    >
                        <Select
                        className={classes.select}
                        options={[
                            { value: '1', label: 'Zoom' },
                            { value: '2', label: 'Google Meet' },
                            
                        ]}
                        placeholder={'Выберите подходящий формат связи'}
                        />
                    </Form.Item>
                    <Form.Item
                    name={'comment'}
                    >
                        <Input.TextArea className={classes.input} placeholder={'Напишите сообщение'}/>
                    </Form.Item>
                    <div className={classes.date}>
                        <p>Выберите удобную дату и время консультации для Вас.</p>
                        <Form.Item
                    rules={[{ required: true, message: 'Введите время визита!' }]}


                        name={'date_of_visit'}
                        >
                            <DatePicker dropdownClassName={classes.dropdown} format={'YYYY-MM-DD HH:mm'} className={classes.datePicker} locale={locale} showTime={{ format: 'HH:mm' }} placeholder={'10.11.2023'}/>
                        </Form.Item>
                    </div>
                    
                    <div className={classes.bottom}>
                        <Form.Item>
                            <Button className={classes.submit} type="primary" htmlType="submit">
                                Получить консультацию
                            </Button>
                        </Form.Item>
                        <Form.Item
                        name={'politika'}
                        valuePropName="checked"
                    rules={[{ required: true, message: 'Согласитесь с политикой' }]}

                    > 
                            <Checkbox>
                            Согласие на обработку персональный данных:
                            Оставляя свои контакты данные вы даёте согласие 
                            на обработку персональнных данных
                            </Checkbox>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </Container>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover />
        </>
    );
};
export default Index;