import { FC, useEffect, useState } from "react";
import Container from "../../components/footer/Container";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store";
import { fetchSlectedCatalogProduct } from "../../store/catalog";
import { useSelectedCatalogProduct, useSimilartProducts } from "../../store/catalog/hooks";
import './Product.scss'
import { Collapse, CollapseProps } from "antd";
import API from "../../constants/api";
import { fetchOrder } from "../../store/order";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { Swiper, SwiperSlide } from "swiper/react";
import classes from './index.module.scss'
import { Pagination } from "swiper/modules";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import '../Main/Popular/Popular.css'

const Product:FC = () => {

      const notifyError = () => {
      toast.error('Выберите размер', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    const notifySuccess = () => {
      toast.success('Добавлено', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    const notifyError2 = () => {
      toast.error('Товара нет на складе', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }



    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [currentProduct, setCurrentProduct] = useState<any>();
    const [currentDrawing, setCurrentDrawing] = useState<any>();


    const params = useParams();

    useEffect(() => {
        if (!params.id) return;
        dispatch(fetchSlectedCatalogProduct(params.id) as any);
    }, [location, dispatch, params]);

    const selectedProduct = useSelectedCatalogProduct();
    const similar = useSimilartProducts();

    useEffect(() => {
      console.log(selectedProduct, '1')
        if (!selectedProduct || !selectedProduct.color_groups[0]) return;
        setCurrentProduct(selectedProduct.color_groups[0]);
        if (selectedProduct.locations) {
        try {
            setCurrentDrawing({
            ...selectedProduct.locations[0],
            selectedCostomTypeId: selectedProduct.locations[0].costom_types[0].id,
            // @ts-ignore
            color_id: selectedProduct.locations[0].costom_types[0].colors[0].id,
            costom_type_id: selectedProduct.locations[0].costom_types[0].id,
            });
        } catch (e) {
            setCurrentDrawing({
            ...selectedProduct.locations[0],
            selectedCostomTypeId: "",
            });
        }
        }
    }, [selectedProduct]);

        const [priceDrawing, setPriceDrawing] = useState(0);
          const [widthHeight, setWidthHeight] = useState({
            width: "",
            height: "",
          });
            const handleSendCostomCost = async () => {
                if (!selectedProduct) return;
                try {
                const res = await API.post(
                    `/products/${selectedProduct.id}/costom-cost`,
                    {
                    costom_type_id: +currentDrawing.costom_type_id,
                    color_id: +currentDrawing.color_id,
                    width: +widthHeight.width,
                    height: +widthHeight.height,
                    }
                );
                setPriceDrawing(Math.ceil(res.data.data.cost));
                } catch (e) {
                console.log(e);
                }
            };

        const items: CollapseProps['items'] = [
        {
            key: '1',
            label: 'Вид нанесения',
            children:  <> 
                  <div className="flex justify-between flex-wrap gap-2">
                    <div className="w-[100%]">
                      <label htmlFor="input2">
                        <span className="ml-[7px]">Место</span>
                      </label>
                      {/*  */}

                      {selectedProduct?.locations?.length && (
                        <form className="w-full p-2">
                          <fieldset>
                            <div className="relative border-b-2 border-black text-gray-800 bg-white ">
                              <>
                                <select
                                  className="appearance-none w-full py-1 px-2 bg-white "
                                  name="whatever"
                                  id="frm-whatever"
                                  onChange={(e) => {
                                    setCurrentDrawing({
                                      ...currentDrawing,
                                      selectedCostomTypeId: e.target.value,
                                    });
                                  }}
                                >
                                  {selectedProduct.locations.map(
                                    (item: any, i) => (
                                      <option value={item.id} key={i}>
                                        {item.name}
                                      </option>
                                    )
                                  )}
                                </select>
                                <div className="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2 text-gray-700 border-l">
                                  <svg
                                    className="h-4 w-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                  >
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                  </svg>
                                </div>
                              </>
                            </div>
                          </fieldset>
                        </form>
                      )}
                    </div>

                    <div className="flex md:justify-between md:flex-row flex-col w-full">
                      <div className="md:w-6/12 w-full">
                        <form className="w-full p-2 ">
                          <label htmlFor="input2">Тип нанесения</label>
                          <fieldset>
                            <div className="relative border-b-2 border-black  text-gray-800 bg-white ">
                              <label htmlFor="frm-whatever" className="sr-only">
                                My field
                              </label>
                              <select
                                onChange={(e) => {
                                  setCurrentDrawing({
                                    ...currentDrawing,
                                    costom_type_id: e.target.value,
                                  });
                                }}
                                className="appearance-none w-full py-1 px-2 bg-white "
                                name="whatever"
                                id="frm-whatever"
                              >
                                {currentDrawing &&
                                  currentDrawing.costom_types &&
                                  currentDrawing.costom_types.map(
                                    (type: any, i: number) => (
                                      <option key={i} value={type.id}>
                                        {type.costom}
                                      </option>
                                    )
                                  )}
                              </select>
                              <div className="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2 text-gray-700 border-l">
                                <svg
                                  className="h-4 w-4"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                              </div>
                            </div>
                          </fieldset>
                        </form>
                      </div>

                      {/*  */}

                      <div className="md:w-6/12 w-full">
                        <form className="w-full p-2">
                          <label htmlFor="input2">Цветность</label>
                          <fieldset>
                            <div className="relative border-b-2 border-black text-gray-800 bg-white ">
                              <label htmlFor="frm-whatever" className="sr-only">
                                My field
                              </label>
                              <select
                                className="appearance-none w-full py-1 px-2 bg-white  "
                                name="whatever"
                                id="frm-whatever"
                                onChange={(e) =>
                                  setCurrentDrawing({
                                    ...currentDrawing,
                                    color_id: e.target.value,
                                  })
                                }
                              >
                                {currentDrawing &&
                                  currentDrawing.selectedCostomTypeId ? (
                                  currentDrawing.costom_types.map(
                                    (type: any, i: number) => {
                                      return type.id ==
                                        currentDrawing.costom_type_id
                                        ? type.colors.map(
                                          (color: any, i: number) => (
                                            <option key={i} value={color.id}>
                                              {color.name}
                                            </option>
                                          )
                                        )
                                        : null;
                                    }
                                  )
                                ) : (
                                  <option value="">
                                    Выберите тип нанесения
                                  </option>
                                )}
                              </select>
                              <div className="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2 text-gray-700 border-l">
                                <svg
                                  className="h-4 w-4"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                              </div>
                            </div>
                          </fieldset>
                        </form>
                      </div>
                    </div>

                    <div className="flex md:justify-between flex-col md:flex-row w-full">
                      <div className="flex  w-5/2 justify-between ">
                        <div className="pl-[15px]">
                          {currentDrawing &&
                            currentDrawing.selectedCostomTypeId ? (
                            currentDrawing.costom_types.map(
                              (type: any, i: number) => {
                                return (
                                  type.id == currentDrawing.costom_type_id && (
                                    <>
                                      <label htmlFor="input2">
                                        Максимальная Ширина({type.width}мм)
                                      </label>

                                      <input
                                        type="number"
                                        defaultValue={0}
                                        onChange={(e) =>
                                          setWidthHeight({
                                            ...widthHeight,
                                            width: e.target.value,
                                          })
                                        }
                                        className=" border-b-2 border-black w-20"
                                      />
                                    </>
                                  )
                                );
                              }
                            )
                          ) : (
                            <input
                              type="text"
                              value={"Выберите тип нанесения"}
                              className=" border-b-2 border-black max-w-[100px] ml-[-6px] px-2"
                            />
                          )}
                        </div>

                        <div>
                          {currentDrawing &&
                            currentDrawing.selectedCostomTypeId ? (
                            currentDrawing.costom_types.map(
                              (type: any, i: number) => {
                                return type.id ==
                                  currentDrawing.costom_type_id ? (
                                  <div className="ml-[15px]">
                                    <label htmlFor="input2 ">
                                      <span>
                                        Максимальная Высота({type.height}мм)
                                      </span>
                                    </label>

                                    <input
                                      type="number"
                                      defaultValue={0}
                                      onChange={(e) =>
                                        setWidthHeight({
                                          ...widthHeight,
                                          height: e.target.value,
                                        })
                                      }
                                      className=" border-b-2 border-black w-20"
                                    />
                                  </div>
                                ) : null;
                              }
                            )
                          ) : (
                            <input
                              type="text"
                              value={"Выберите тип нанесения"}
                              className=" border-b-2 border-black max-w-[100px] ml-[8px] px-2"
                            />
                          )}
                        </div>
                      </div>
                      <div className="flex justify-center items-center py-4 w-7/12">
                        <button
                          onClick={handleSendCostomCost}
                          className="font-jost text-[#06AF5E]  border !border-black rounded-full ... px-5 py-2"
                        >
                          {priceDrawing ? "Нанесение Добавлено" : "Применить"}
                        </button>
                        {!!priceDrawing && (
                          <div className="flex flex-col flex-wrap grid lg:grid-cols-2">
                            <span>Цена:</span>
                            <p className="flex justify-center items-center">
                              {" "}
                              {priceDrawing}
                              c
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
        },
        ];


    const [mainImg, setMainImg] = useState('')
    const [currentColor, setCurrentColor] = useState<any>()
    const [currentSize, setCurrentSize] = useState()

    const [error, setError] = useState("");

    useEffect(() => {
        setMainImg(currentProduct?.images[0].big)
        setCurrentSize(currentProduct?.sizes[0].id)
    }, [currentProduct])

    useEffect(() => {
        setCurrentColor(selectedProduct?.color_groups[0].id)
    }, [selectedProduct])

    const handleSendProductToCart = async () => {
    if (!currentProduct) return;
    setError("");

    const sizes = [{
      product_size_id: currentSize,
      quantity: 1,
    }]

    if (!currentSize) {
      // setError("Добавьте количество товара");
      notifyError()
      return;
    }

    try {
        await API.post("/orders/cart", {
        product_color_group_id: currentProduct.id,
        sizes,
        costoms: +currentDrawing.costom_type_id ? [
          {
            costom_type_id: +currentDrawing.costom_type_id, 
            color_id: +currentDrawing.color_id,
            width: +widthHeight.width,
            height: +widthHeight.height,
          }
        ] : []
      });
      dispatch(fetchOrder() as any);
      notifySuccess()
    } catch (e: any) {
      // navigate("/signup");
      if(e?.response?.data?.detail === "insufficient quantity of goods in stock"){
        notifyError2()
      }
      if(e?.response?.data?.detail === "Недопустимый токен."){
      navigate("/signup");

      }
      console.log(e);
    }
    };

    const isMobile = useMediaQuery('(max-width: 768px)');

    useEffect(() => {
      console.log(similar);
    }, [similar])

    const [sim, setSim] = useState<any>()

    useEffect(() => {
      setSim(similar?.slice(0, 3))
    }, [similar])

    return (
            <Container>
              <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover />
                <div className="productWrapper">
                  {
                    !isMobile
                    ?
                    <div className="productImgWrapper">
                        <div className="productSecondaryImg">
                            {currentProduct?.images.slice(0, 5).map((item: any, i: number) => (
                                <div onClick={() => setMainImg(item.big)}>
                                    <img src={item.small} alt="secondaryImg" key={i}/>
                                </div>
                            ))}
                        </div>
                        <div className="productMainImg">
                            <img src={mainImg} alt="mainImg"/>
                        </div>
                    </div>
                    :
                    <div>

                    <Swiper
                      modules={[Pagination]}
                      navigation={!isMobile}
                      pagination={isMobile ? {clickable: true} : false}
                      height={isMobile ? 414 : 556}
                      className={classes.swiper}
                      spaceBetween={0}
                      slidesPerView={1}
                      
                    >
                    
                    {currentProduct?.images.slice(0, 5).map((item: any, i: number) => (
                      <SwiperSlide>
                          <img src={item.big} alt="secondaryImg" key={i}/>
                      </SwiperSlide>
                    ))}
                </Swiper>
                    </div>
                  }
                    

                    <div className="productInfo">
                        <div className="productInfoBlock">
                            <p className="productTitle">{selectedProduct?.full_name}</p>
                            <p className="productPrice">{Math.round(currentProduct?.price)} c</p>
                        </div>

                        <p className="productDescription">{selectedProduct?.description}</p>
                        <hr />
                        <h4 style={{margin: '20px 0'}}>На складе: {selectedProduct?.total_stock}</h4>
                        <hr />

                        {selectedProduct?.color_groups.length ?
                            <>
                                <p className="productColor">Цвет товара</p>
                                <div className="productColorBlock">
                                    {selectedProduct?.color_groups.map((item, i) => (
                                        <span onClick={() => {setCurrentColor(item.id); setCurrentProduct(selectedProduct.color_groups[i])}} key={i} className={`productColorButton ${currentColor === item.id ? 'productColorButtonActive' : ''}`}>{item?.color?.name}</span>
                                    ))}
                                </div>
                                <hr />
                            </>
                                :
                            <></>    
                        }
                        {currentProduct?.sizes.length ?
                            <>
                                {currentProduct?.sizes[0].size ? <p className="productColor">Размер</p> : ''}
                                <div>
                                    {currentProduct?.sizes.map((item:any, i:number) => (
                                      <>
                                        {!!item.quantity && item.size ? <span key={i} onClick={() => setCurrentSize(item.id)} className={`productColorButton ${currentSize === item.id ? 'productColorButtonActive' : ''}`}>{item.size}</span> : ''}
                                      </>
                                    ))}
                                </div>
                            </>
                            : 
                            <></>
                        }
                        <div className="productAdd" onClick={() =>  handleSendProductToCart()}>Добавить в корзину</div>
                        <hr />
                        {+currentDrawing?.costom_type_id ? <Collapse items={items} ghost expandIconPosition={'end'}/> : <></>}
                    </div>
                </div>

                <div>

                          {sim?.length ? 
            <div className="popWrapper">
                    <div className="popTitleButtons">
                        <p className="companiesTitle">Популярные подарки MMT group</p>
                        {similar?.length > 5 && <div className="popButtons">
                            <div onClick={() => setSim(similar?.slice(0, 3))}><LeftOutlined rev={undefined} /></div>
                            <div onClick={() => setSim(similar?.slice(3, 6))}><RightOutlined rev={undefined} /></div>
                        </div>}
                    </div>
                    <div className="popularCards">
                        {sim && sim?.map((item: any) => (
                            <div className="catalogCardWrapper1" onClick={() => navigate(`/product/${item.id}`)}>
                                <div>   
                                    <img style={{objectFit: 'contain',height: '483px',  
                                  }} className="catalogCardImg" src={item?.colors[0]?.images[0]?.big} alt={item.name} />
                                </div>
                                <div style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'flex-start',
                                  gap: '20px',
                                  marginBottom: '24px',
                                  }} 
                                  className="catalogCardInfo"> 
                                    <p style={{ fontSize: '20px', fontWeight: 600,}}                            
                                      className={classes.catalogCardTitle}>{item.name}</p>
                                    <p className={classes.catalogCardPrice}>{item.price} c</p>
                                </div>
                            </div>
                        ))}
                    </div>
            </div>
        : <></>
        }
                </div>
            </Container>
    )
}

export default Product