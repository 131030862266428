import { FC } from 'react';
import Container from '../../footer/Container';
import logo from '../../../assets/img/logo.svg'
import { Link } from 'react-router-dom';
import basket from '../../../assets/icons/Basket.svg'
import phone from '../../../assets/icons/Phone.svg'
import search from '../../../assets/icons/Search.svg'
import user from '../../../assets/icons/User.svg'
import HeaderSecondary from "../HederSecondary";

interface HeaderDesktopProps {
    hadnleNavigate: (lint: string) => void,
    setSecondaryHeaderOpen: any,
    token: string | null,
    isSearchOpen: boolean,
    setSearchContent: any,
    searchContent: any,
    handleSubmit: (e: any) => void,
    setSearchOpen: any,
    secondaryHeaderOpen: boolean,
}

const HeaderDesktop: FC<HeaderDesktopProps> = (props) => {
    const {secondaryHeaderOpen, setSearchOpen, handleSubmit, hadnleNavigate, setSecondaryHeaderOpen, token, isSearchOpen, setSearchContent, searchContent} = props;
    return (
        <>
        <div className="headerHolder"></div>

        <div className="headerCombine">
            <Container>
                <div className="headerWrapper">
                <div className="headerLogo" 
                onClick={() => hadnleNavigate('/')}
                onMouseEnter={() => setSecondaryHeaderOpen(false)}

                >
                    <img style={{cursor: 'pointer', width: 75, maxWidth: '75px'}} src={logo} alt="ммт" />
                </div>
                <div 
                className="headerCatalog"
                onMouseEnter={() => setSecondaryHeaderOpen(!secondaryHeaderOpen)}
                >
                    <div className="headerMenu">
                        <div></div>
                        <div></div>
                    </div>
                    <div onClick={() => hadnleNavigate('/catalog')}>Каталог</div>
                </div>
                <div 
                    className="headerNavigation"
                    onMouseEnter={() => setSecondaryHeaderOpen(false)}
                    
                >
                    <div onClick={() => hadnleNavigate('/about')}>Компания</div>
                    <div onClick={() => hadnleNavigate('/portfolio')}>Портфолио</div>
                    <div onClick={() => hadnleNavigate('/blog')}>Блог</div>
                    <div onClick={() => hadnleNavigate('/')}><a href="#faq">FAQ</a></div>
                    <div onClick={() => hadnleNavigate('/contacts')}>Контакты</div>
                    <div onClick={() => hadnleNavigate('/lookbook')}>Lookbook</div>
                </div>
                <div 
                    className="headerActions"
                    onMouseEnter={() => setSecondaryHeaderOpen(false)}

                >
                    {token && <img src={basket} onClick={() => hadnleNavigate('/cart')} alt="корзина"/>}
                    <a href="tel:+996555883500" style={{
    display: 'flex',
    alignItems: 'center'}}><img src={phone} alt="телефон"/></a>
                    {!isSearchOpen && <img onClick={() => setSearchOpen(true)} src={search} alt="поиск"/>}
                    {isSearchOpen && <form className="p-0" onSubmit={handleSubmit}>
                        <label className="relative flex justify-end flex-row items-center !mb-0">
                            <input
                                onChange={(e: any) =>
                                setSearchContent(e.target.value)
                                }
                                className="placeholder:text-white block bg-[#1F1F1F] md:w-72  border-none rounded-full py-2 pl-9 !pr-0 shadow-sm focus:outline-none  sm:text-sm text-white"
                                placeholder="Поиск..."
                                type="text"
                                name="search"
                            />
                            <Link
                                className="absolute left-2 focus:outline-none h-2 w-auto sm:h-5"
                                to={`/catalog?search=${searchContent}`}>
                                <img
                                    src={search}
                                    onClick={() =>
                                        setTimeout(() => window.location.reload(), 100)
                                    }
                                    alt=""
                                />
                            </Link>
                        </label>
                    </form>}
                    {token && <img src={user} onClick={() => hadnleNavigate('/profile')} alt="пользователь"/>}
                    {!token &&     
                        <div className="flex gap-4 justify-between items-center w-60 ml-4">
                            <Link to="/signup">
                                <button className="text-white cursor-pointer focus:outline-none flex items-center text-center justify-center">
                                Регистрация
                                </button>
                            </Link>

                            <Link to="/signin">
                                <button
                                className=" flex justify-center rounded-full w-auto text-white px-10 items-center py-[13px] bg-[#1F1F1F] transition .4s hover:scale-105"
                                >
                                Войти
                                </button>
                            </Link>
                        </div>
                    }
                </div>
                </div>  
            </Container>
            {secondaryHeaderOpen && <HeaderSecondary hadnleNavigate={hadnleNavigate} setOpen={setSecondaryHeaderOpen}/>}
        </div>
        </>
    );
};

export default HeaderDesktop;