import { FC } from "react";
import classes from './CatalogCard.module.scss';
import { useNavigate } from "react-router-dom";

interface IProps{
    title: string
    price: string
    img: string
    num: string
    stock: string
    id: string
}

const CatalogCard:FC<IProps> = (props) => {

    const navigate = useNavigate();

    const {title, price, img, num, stock, id} = props

    return(
        <div className={classes.catalogCardWrapper} onClick={() => navigate(`/product/${id}`)}>
            <img className={classes.catalogCardImg} src={img} alt={title} />
            <div className={classes.catalogCardInfo}> 
                <p className={classes.catalogCardTitle}>{title}</p>
                <p className={classes.catalogCardPrice}>{price} c</p>
            </div>
            <div className={classes.catalogCardExtraInfo}>
                <div className={classes.catalogCardArticle}>арт. {num}</div>
            </div>
        </div>
    )
}

export default CatalogCard;