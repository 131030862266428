import { FC, useState } from "react";
import './Special.css'
import { useNavigate } from "react-router-dom";
import one from './Group122.png'
import two from './Group123.png'
import three from './Group124.png'
import ModalWomanBruhForm from "../../../components/ModalWomanBruhForm/ModalWomanBruhForm";

interface ISpecialItemProps{
    title: string
    description: string
    action: () => void
    actionTitle: string
    background: string
}

const SpecialItem:FC<ISpecialItemProps> = (props) => {

    const {title, description, action, actionTitle, background} = props
    
    return(
        <div style={{background: `url(${background}) right no-repeat`, backgroundSize: 'cover'}} className="specialItem">
            <p className="specialItemTitle">{title}</p>
            <p className="specialItemDesc">{description}</p>
            <p className="specialItemAction" onClick={() => action()}>{actionTitle}</p>
        </div>
    )
}

const Special:FC = () => {

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const handleClose = () => setOpen(false);
    const handleClose2 = () => setOpen2(false);

    const specialArr = [
        {
            title: 'Приехать в шоу рум',
            description: 'Посетите наш шоу-рум, который является крупнейшим в Бишкеке, с более чем 5000 живых образцов подарков.',
            action: () => setOpen2(true),
            actionTitle: 'Узнать подробнее',
            background: three
        },
        {
            title: 'Пригласить менеджера в офис',
            description: 'Пригласите нашего менеджера в ваш офис, и мы с удовольствием представим вам образцы новых продуктов лично.',
            action: () => setOpen(true),
            actionTitle: 'Пригласить',
            background: one
        },        
        {
            title: 'Видео консультация',
            description: 'Заполните форму для видео-консультации,  и наши эксперты проведут онлайн-презентацию подарков специально для вас.',
            action: () => navigate('/videoConsulting'),
            actionTitle: 'Оставить заявку',
            background: two
        }
    ]

    return (
    <>
        <div className="specialWrapper">
            <p className='specialTitle'>Специально для вас</p>
            <div className="specialBlock">
                {specialArr.map((item) => (
                    <SpecialItem 
                        key={item.title}
                        title={item.title}
                        description={item.description}
                        action={item.action}
                        actionTitle={item.actionTitle}
                        background={item.background}
                    />
                ))}
            </div>
        </div>
        <ModalWomanBruhForm type_of_appli={'1'} open={open} handleClose={handleClose} title={'Выездной шоу-рум к Вам в офис'}/>
        <ModalWomanBruhForm type_of_appli={'2'} open={open2} handleClose={handleClose2} title={'Шоу-рум MMT group по адресу Сонкульский переулок, 8'}/>
    </>
    )
}

export default Special;