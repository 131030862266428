import { FC, useState } from "react";
import './Header.scss'

import { Link, useNavigate } from "react-router-dom";
import Container from "../footer/Container";
import { useAppDispatch } from "../../store";
import { setSearchValue } from "../../store/catalog/actions";
import HeaderDesktop from "./desktop/HeaderDesktop";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import HeaderMobile from "./mobile/HeaderMobile";



const Header:FC = () => {

    const [ secondaryHeaderOpen, setSecondaryHeaderOpen ] = useState(false)

    const [searchContent, setSearchContent] = useState<any>('')
    const [isSearchOpen, setSearchOpen] = useState(false)

    const token = localStorage.getItem("token");

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const hadnleNavigate = (link: string) => {
        navigate(link)
        setSecondaryHeaderOpen(false);
        window.scroll({top: 0, behavior: 'smooth'})
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        dispatch(setSearchValue(searchContent))
    };

    const isMobile = useMediaQuery('(max-width: 768px)')

    return (
        isMobile
        ?
        <HeaderMobile
        hadnleNavigate={hadnleNavigate}
        setSecondaryHeaderOpen={setSecondaryHeaderOpen}
        token={token}
        isSearchOpen={isSearchOpen}
        setSearchContent={setSearchContent}
        searchContent={searchContent}
        handleSubmit={handleSubmit}
        setSearchOpen={setSearchOpen}
        secondaryHeaderOpen={secondaryHeaderOpen}
        />
        :

    <HeaderDesktop 
        hadnleNavigate={hadnleNavigate}
        setSecondaryHeaderOpen={setSecondaryHeaderOpen}
        token={token}
        isSearchOpen={isSearchOpen}
        setSearchContent={setSearchContent}
        searchContent={searchContent}
        handleSubmit={handleSubmit}
        setSearchOpen={setSearchOpen}
        secondaryHeaderOpen={secondaryHeaderOpen}
    />
    )
}

export default Header