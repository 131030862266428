import { FC } from 'react';
import classes from './FaqForm.module.scss';
import { Button, Checkbox, Form, Input } from 'antd';
import { PUBLIC_API } from '../../constants/api';
import { ToastContainer, toast } from 'react-toastify';
import { useMediaQuery } from '../../hooks/useMediaQuery';

const FaqForm: FC<{showTitle?: boolean, showQuestion?: boolean}> = (props) => {
    const {showTitle=true, showQuestion=false} = props;

    const [form] = Form.useForm();
    const onFinish = async (values: any) => {
        const newValues = {...values}        
        delete newValues.politika
        const backData = {
            ...newValues,
        }
        try {
            const res = await PUBLIC_API.post('partners/application-call/', backData)
            if (res.data.status === 'CREATED'){
                toast.success('Заявка отправлена')
            }
        } catch (error) {
            toast.error('Произошла ошибка')
            return;
        }
    }
    const onFinishFailed = (values: any) => {
        toast.error('Заполните поля!')
    }
    const isMobile = useMediaQuery('(max-width: 768px)')
    return (
        <>
        <Form
            form={form}
            onFinish={onFinish} 
            onFinishFailed={onFinishFailed} 
        >
            {
                showTitle
                &&
                <h1 className={classes.headTitle}>Готовы ответить на любые вопросы — спрашивайте</h1>

            }
            <div className={classes.inputs}>
                <Form.Item rules={[{ required: true, message: 'Введите имя!' }]} name={'name'}>
                    <Input className={classes.input} placeholder={'Имя'}/>
                </Form.Item>
                <Form.Item rules={[{ required: true, message: 'Введите номер телефона!' }]} name={'phone_number'}>
                    <Input className={classes.input} placeholder={'+996'}/>
                </Form.Item>
                <Form.Item rules={[{ required: true, message: 'Введите эмейл!' }]} name={'email'}>
                    <Input className={classes.input} placeholder={'Эл. адрес'}/>
                </Form.Item>
                
                {
                !isMobile
                &&
                <Form.Item>

            <Button className={classes.submit} type="primary" htmlType="submit">
                    Задать вопрос
                </Button>
            </Form.Item>
            }
            </div>
            {
                showQuestion
                &&
                <div>
                    <Form.Item name={'question'}>
                        <Input.TextArea className={classes.input} placeholder={'Вопрос'}></Input.TextArea>
                    </Form.Item>
                </div>
            }
            <div className={classes.bottom}>
            <Form.Item
                name={'politika'}
                valuePropName="checked"
                rules={[{ required: true, message: 'Согласитесь с политикой' }]}
            >
                <Checkbox>
                Согласие на обработку персональный данных:
Оставляя свои контакты данные вы даёте согласие на обработку персональнных данных
                </Checkbox>
            </Form.Item>
            {
                isMobile
                &&
                <Form.Item>

            <Button className={classes.submit} type="primary" htmlType="submit">
                    Задать вопрос
                </Button>
            </Form.Item>
            }
                
            </div>
            
        </Form>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover />
        </>
        
    );
};
export default FaqForm;