import { FC } from "react";
import './WhyMMT.scss'
import one from './6.svg'
import two from './7.svg'
import three from './Depth.svg'
import four from './Portal.svg'
import five from './Group 89.svg'
import six from './Smile.svg'

interface IWhyMMTItemProps{
    icon: string
    title: string
    description: string
}

const WhyMMTItem:FC<IWhyMMTItemProps> = (props) => {

    const {icon, title, description} = props

    return (
        <div className="whyMMTItem">
            <img src={icon} alt="icon"/>
            <p className="whyMMTTitle">{title}</p>
            <p className="whyMMTDescr">{description}</p>
        </div>
    )
}

const WhyMMT:FC = () => {

    const whyArr = [
        {
            icon: five,
            title: 'Многолетний опыт',
            description: 'Более 10 лет успешной работы в области корпоративного мерчандайзинга, что подтверждает нашу экспертность и надежность.',
        },
        {
            icon: two,
            title: 'Работа с крупными брендами',
            description: 'Сотрудничество с ведущими компаниями и брендами в нашей стране, что подтверждает высокую оценку нашей работы на рынке.',
        },
        {
            icon: one,
            title: 'Качество и стиль',
            description: 'Высокое качество материалов и производства, гарантирующее надежность и долговечность нашего мерча.',
        },
        {
            icon: four,
            title: 'Клиенториентированный подход',
            description: 'Мы ставим вас и ваши потребности на первое место, предлагая гибкие условия и индивидуальные скидки.',
        },
        {
            icon: six,
            title: '1000+ довольных клиентов',
            description: 'Высокое качество материалов и производства, гарантирующее надежность и долговечность нашего мерча.',
        },
        {
            icon: three,
            title: '2800+ продукций для брендирования',
            description: 'Высокое качество материалов и производства, гарантирующее надежность и долговечность нашего мерча.',
        }
    ]

    return(
        <div className="whyWrapper">
            <div className="whyTitleWrapper">
                <p className="whyTitle">Почему бренды выбирают MMT group</p>
            </div>
            <div className="whyMMTBlock">
                {whyArr.map((item) => (
                    <WhyMMTItem 
                        key={item.title}
                        icon={item.icon}
                        title={item.title}
                        description={item.description}
                    />
                ))}
            </div>
        </div>
    )
}


export default WhyMMT;