import { FC } from 'react';
import burger from './burger.svg';
import classes from './HeaderMobile.module.scss'
import logo from '../../../assets/img/logo.svg'
import basket from '../../../assets/icons/Basket.svg'
import phone from '../../../assets/icons/Phone.svg'
import search from '../../../assets/icons/Search.svg'
import user from '../../../assets/icons/User.svg'
import { Link } from 'react-router-dom';
import HeaderSecondary from '../HederSecondary';


interface HeaderMobileProps {
    hadnleNavigate: (lint: string) => void,
    setSecondaryHeaderOpen: any,
    token: string | null,
    isSearchOpen: boolean,
    setSearchContent: any,
    searchContent: any,
    handleSubmit: (e: any) => void,
    setSearchOpen: any,
    secondaryHeaderOpen: boolean,
}

const HeaderMobile: FC<HeaderMobileProps> = (props) => {
    const {secondaryHeaderOpen, setSearchOpen, handleSubmit, hadnleNavigate, setSecondaryHeaderOpen, token, isSearchOpen, setSearchContent, searchContent} = props;

    return (
        <>
            <div className={classes.background}>
            <div onClick={()=>setSecondaryHeaderOpen(!secondaryHeaderOpen)} className={classes.burger}><img src={burger} alt="burger"/></div>
            <img onClick={() => hadnleNavigate('/')} className={classes.logo} src={logo} alt="ммт" />
            <div 
                    className={classes.headerActions}
                    onMouseEnter={() => setSecondaryHeaderOpen(false)}
                >
                    {!isSearchOpen && token && <img src={basket} onClick={() => hadnleNavigate('/cart')} alt="корзина"/>}
                    {/* {!isSearchOpen && <a href="tel:+996555883500"><img src={phone} alt="телефон"/></a>} */}
                    {!isSearchOpen && <img onClick={() => setSearchOpen(true)} src={search} alt="поиск"/>}
                    {isSearchOpen && <form className="p-0" onSubmit={handleSubmit}>
                        <label className={classes.mobheaderSearch}>
                            <input
                                onChange={(e: any) =>
                                setSearchContent(e.target.value)
                                }
                                className="placeholder:text-white block bg-[#1F1F1F] md:w-72  border-none rounded-full py-2 pl-9 !pr-0 shadow-sm focus:outline-none  sm:text-sm text-white w-[230px]"
                                placeholder="Поиск..."
                                type="text"
                                name="search"
                            />
                            <Link
                                className={classes.searchImg}
                                to={`/catalog?search=${searchContent}`}>
                                <img
                                    src={search}
                                    onClick={() =>
                                        setTimeout(() => window.location.reload(), 100)
                                    }
                                    alt=""
                                />
                            </Link>
                        </label>
                    </form>}
                    {/* {!isSearchOpen && token && <img src={user} onClick={() => hadnleNavigate('/profile')} alt="пользователь"/>} */}
                </div>
        </div>
            {secondaryHeaderOpen && <HeaderSecondary hadnleNavigate={hadnleNavigate} setOpen={setSecondaryHeaderOpen}/>}
        </>

    );
};

export default HeaderMobile;