import { Button, Checkbox, DatePicker, Form, Input, Modal } from 'antd';
import { FC } from 'react';
import classes from './ModalWomanBruhForm.module.scss'
import takoiWomanNet from '../../assets/takoiWomanNet.png';
import locale from 'antd/es/date-picker/locale/ru_RU';

import 'dayjs/locale/ru';
import { PUBLIC_API } from '../../constants/api';
import { ToastContainer, toast } from 'react-toastify';
import { useMediaQuery } from '../../hooks/useMediaQuery';

interface ModalWomanBruhFormProps {
    title?: string,
    open: boolean,
    handleClose: () => void,
    type_of_appli: '1' | '2' | '3'
}

const ModalWomanBruhForm: FC<ModalWomanBruhFormProps> = (props) => {
    const {title, open, handleClose, type_of_appli} = props;
    const [form] = Form.useForm();
    const onFinish = async (values: any) => {
        const newValues = {...values}        
        delete newValues.politika
        const date = values?.date_of_visit?.format('YYYY-MM-DD HH:mm')
        const backData = {
            ...newValues,
            date_of_visit: date,
            type_of_appli: type_of_appli,
        }
        try {
            if(!(type_of_appli === '3')){
                const res = await PUBLIC_API.post('partners/application/', backData)
                if (res.data.status === 'CREATED'){
                toast.success('Заявка отправлена')
            }
            }else{
                const res = await PUBLIC_API.post('partners/order-call/', {name: values.name, phone_number: values.phone_number, date: date})
                if (res.data.status === 'CREATED'){
                toast.success('Заявка отправлена')
            }
        }} catch (error) {
            toast.error('Произошла ошибка')
            return;
        }
    }
    const onFinishFailed = (values: any) => {
        toast.error('Заполните поля!')
    }
    const isMobile = useMediaQuery('(max-width: 768px)');
    
    return (
        <>
        <Modal width={isMobile ? undefined : 1182} className={classes.modal} onCancel={handleClose} footer={''} open={open}>
            <div className={classes.wrapper}>
                <div>
                    <h1 className={classes.headTitle}>{title}</h1>
                    <Form
                        onFinishFailed={onFinishFailed}
                        onFinish={onFinish}
                        className={classes.form}
                        form={form}
                    >
                        <div className={classes.grid}>
                            <Form.Item rules={[{ required: true, message: 'Введите имя!' }]} name={'name'}>
                                <Input placeholder={'Имя'} className={classes.input}/>
                            </Form.Item>
                            <Form.Item rules={[{ required: true, message: 'Введите номер телефона!' }]} name={'phone_number'}>
                                <Input placeholder={'+996'} className={classes.input}/>
                            </Form.Item>
                           { !(type_of_appli === '3') && <Form.Item rules={[{ required: true, message: 'Введите эмейл!' }]} name={'email'}>
                                <Input placeholder={'Эл. адрес'} className={classes.input}/>
                            </Form.Item>}
                            { !(type_of_appli === '3') &&<Form.Item rules={[{ required: true, message: 'Введите время визита!' }]} name={'date_of_visit'}>
                            <DatePicker dropdownClassName={classes.dropdown} format={'YYYY-MM-DD HH:mm'} className={classes.datePicker} locale={locale} showTime={{ format: 'HH:mm' }} placeholder={'10.11.2023'}/>
                            </Form.Item>}
                        </div>
                        { !(type_of_appli === '3') && <Form.Item name={'comment'}>
                            <Input.TextArea className={classes.input} placeholder={'Ваши пожелания'}>

                            </Input.TextArea>
                        </Form.Item>}
                        { (type_of_appli === '3')&&<Form.Item rules={[{ required: true, message: 'Введите время визита!' }]} name={'date_of_visit'}>
                            <DatePicker dropdownClassName={classes.dropdown} format={'YYYY-MM-DD HH:mm'} className={classes.datePicker} locale={locale} showTime={{ format: 'HH:mm' }} placeholder={'10.11.2023'}/>
                            </Form.Item>}
                        <div className={classes.submitWrapper}>
                            <Form.Item>
                                <Button className={classes.submit} type="primary" htmlType="submit">
                                    Получить консультацию
                                </Button>
                            </Form.Item>
                             { !(type_of_appli === '3') && <Form.Item
                                name={'politika'}
                                valuePropName="checked"
                                rules={[{ required: true, message: 'Согласитесь с политикой' }]}

                            > 
                                <Checkbox className={classes.checkBox}>
                                Согласие на обработку персональный данных:
                                Оставляя свои контакты данные вы даёте согласие 
                                на обработку персональнных данных
                                </Checkbox>
                            </Form.Item>}
                        </div>
                    </Form>
                </div>
                {
                    !isMobile
                    &&
                <img src={takoiWomanNet} alt={""}/>
                }
            </div>
        </Modal>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover />
        </>
    );
};

export default ModalWomanBruhForm;