import { Dispatch } from "@reduxjs/toolkit";
import { PUBLIC_API } from "../../constants/api";
import {
  fetchCatalogSuccess,
  fetchFiltersSuccess,
  fetchSelectedCatalogProductSuccess,
  fetchSimilarProductsSuccess,
} from "./actions";

export const fetchSlectedCatalogProduct =
  (id: string) => async (dispatch: Dispatch) => {
    try {
      const res: any = await PUBLIC_API.get(`/products/${id}`);
      dispatch(fetchSelectedCatalogProductSuccess(res.data.data));
      dispatch(fetchSimilarProductsSuccess(res.data.similar_products));
    } catch (e) {
      console.log(e);
    }
  };


export const getCatalogProducts = (params?: any) => async (dispatch: Dispatch) => {
    try {
      const res: any = await PUBLIC_API.get('/products/all', { ...params })
    } catch (e) {
      console.log(e);
    }
}

export const getCatalogCategoryProducts = (params?: any) => async (dispatch: Dispatch) => {
    try {
      const res: any = await PUBLIC_API.get('products/all', {params: params} )
      dispatch(fetchCatalogSuccess(res.data))
    } catch (e) {
      console.log(e);
    }
}
export const getFiltersProducts = (params?: any) => async (dispatch: Dispatch) => {
    try {
      const res: any = await PUBLIC_API.get(`/products/filters/`, {params: params} )
      dispatch(fetchFiltersSuccess(res.data))
    } catch (e) {
      console.log(e);
    }
}