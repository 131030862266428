import { FC, useEffect, useState } from "react";
import { getCatalogCategoryProducts, getFiltersProducts } from "../../store/catalog";
import { useAppDispatch } from "../../store";
import { useLocation } from "react-router-dom";
import { useCatalogProducts, useFilters } from "../../store/catalog/hooks";
import Container from "../../components/footer/Container";
import CatalogCard from "../../components/CatalogCard";
import InfiniteScroll from "react-infinite-scroll-component";
import CatalogFilters from "../../components/CatalogFilters";
import './Catalog.scss'
import { useMediaQuery } from "../../hooks/useMediaQuery";
import filterImage from '../../assets/icons/Filter.svg';

const Catalog:FC = () => {



    const { search } = useLocation();
    const categoryId = new URLSearchParams(search).get("categoryId");
    const catalog_pk = new URLSearchParams(search).get("catalog_pk");
    const searchData = new URLSearchParams(search).get("search");

    const dispatch = useAppDispatch();

    const [selectedFilters, setSelectedFilters] = useState<{
        colors: any[];
        materials: any[];
        custom_types: any[];
        types: any[];
        category: any;
        search: any;
        catalog_pk: any
    }>({
        colors: [],
        materials: [],
        custom_types: [],
        types: [],
        category: categoryId,
        search: searchData,
        catalog_pk: catalog_pk
    });
    useEffect(() => {
        dispatch(getCatalogCategoryProducts({offset: 0,  ...selectedFilters}) as any)
    }, [categoryId, searchData, catalog_pk]);


    const catalogProducts = useCatalogProducts();

    const URL = new URLSearchParams(catalogProducts.next?.toString()?.split('?')[ 1 ])
    const limit: number = Number(URL.get('limit')) || 12;
    const offset: number = Number(URL.get('offset')) || 0;


    const [catalogProductsData, setCatalogProductsData] = useState<any>([])

    useEffect(() => {
      if(JSON.stringify(catalogProducts?.results) && !JSON.stringify(catalogProductsData).includes(JSON.stringify(catalogProducts?.results))){
        setCatalogProductsData((prev: any) => [...prev, ...catalogProducts?.results])
      } 
    }, [catalogProducts]);

    const fetchData = async (off: any = offset, lim: any = limit, params: any = selectedFilters) => {
        await dispatch(getCatalogCategoryProducts( {
            offset: off,
            limit: lim || undefined, 
            ...params
        }) as any)
    }

    useEffect(() => {
        dispatch(getFiltersProducts() as any)
    }, [])

    const filters = useFilters();

    const isMobile = useMediaQuery('(max-width: 768px)')
    const [openFilters, setOpenFiters] = useState(false);

    const handleOpenFilters = () => setOpenFiters(true);

    const [loading, setLoading] = useState(
                                <>
                                    <div className="animate-pulse md:w-72 w-64 h-80 mb-10 bg-gray-200 dark:bg-gray-700"></div>
                                    <div className="animate-pulse md:w-72 w-64 h-80 mb-10 bg-gray-200 dark:bg-gray-700"></div>
                                    <div className="animate-pulse md:w-72 w-64 h-80 mb-10 bg-gray-200 dark:bg-gray-700"></div>
                                    <span className="sr-only">Loading...</span>
                                </>
                                )

    useEffect(() => {
        if(catalogProducts.length !== 0){
        setLoading(<div className={'notFound_catalog'}>Ничего не найдено</div>)
        }
    }, [catalogProductsData])

    return(
        <Container id={'filters_container_page'}>
            <div>
                <div className={'catalog_top_block'}>
                <p className="catalogTitle">
                    Каталог
                </p>
                {
                    isMobile
                    &&
                    <p onClick={handleOpenFilters} className={'catalog_filters_mobile'}>
                        Фильтры
                        <img src={filterImage} alt=""/>
                    </p>
                }
                </div>
                <div className="catalogWrapper">
                    <CatalogFilters 
                        handleClose={() => setOpenFiters(false)}
                        selectedFilters={selectedFilters} 
                        setSelectedFilters={setSelectedFilters} 
                        setCatalogProductsData={setCatalogProductsData} 
                        filters={filters}  
                        fetchData={fetchData}
                        searchData={searchData}
                        openFilters={openFilters}
                    />
                    {
                        catalogProductsData.length <= 0
                        ?
                        loading
                        :
                    <div style={{width: '100%'}}>
                        <InfiniteScroll
                            className="catalogCardsScroll"
                            dataLength={catalogProductsData?.length || 0}
                            hasMore={Boolean(catalogProducts?.next)}
                            loader={
                                <>
                                    <div className="animate-pulse md:w-72 w-64 h-80 mb-10 bg-gray-200 dark:bg-gray-700"></div>
                                    <div className="animate-pulse md:w-72 w-64 h-80 mb-10 bg-gray-200 dark:bg-gray-700"></div>
                                    <div className="animate-pulse md:w-72 w-64 h-80 mb-10 bg-gray-200 dark:bg-gray-700"></div>
                                    <div className="animate-pulse md:w-72 w-64 h-80 mb-10 bg-gray-200 dark:bg-gray-700"></div>
                                    <div className="animate-pulse md:w-72 w-64 h-80 mb-10 bg-gray-200 dark:bg-gray-700"></div>
                                    <div className="animate-pulse md:w-72 w-64 h-80 mb-10 bg-gray-200 dark:bg-gray-700"></div>
                                    <span className="sr-only">Loading...</span>
                                </>
                            }
                            next={fetchData}
                        >
                            {catalogProductsData?.map((item: any, i: number) => (
                                <CatalogCard  
                                    title={item.name}
                                    price={item.price}
                                    img={item?.colors[0]?.images[0]?.small}
                                    num={item.article}
                                    stock={item.total_stock}
                                    id={item.id}
                                    key={i} 
                                />
                            ))}
                        </InfiniteScroll>
                    </div>
                    }
                </div>
            </div>
        </Container>
    );
};


export default Catalog;