import { FC, useEffect, useState } from "react";
import './Portfolio.scss'
import Container from "../../components/footer/Container";
import { PUBLIC_API } from "../../constants/api";
import { AxiosResponse } from "axios";
import { Link } from "react-router-dom";

interface Props{
    img: string
    title: string
    id: string,
}

const PortfolioItem:FC<Props> = (props) => {

    const {img, title, id} = props

    return (
        <Link to={`/portfolio/${id}`} >
        <div className="porfolioItem">
            <img src={img} alt={title}/>
            <p className="porfolioItemTitle">{title}</p>
            <p className={'portfolio_item_link'} >Подробнее</p>
        </div>
        </Link>
    )
}

const getTabsPortfolio = async(): Promise<AxiosResponse<any> | null> => {
    try {
        const res: AxiosResponse<any> = await PUBLIC_API.get("/portfolio/categoryes/");
        return res.data.data;
    }catch{
        return null
    }

}
const fetchPortfolio = async (params?: object): Promise<any | null> => {
    try {
      const res: AxiosResponse<any> = await PUBLIC_API.get("/portfolio", {params});      
      return res.data
    } catch (e) {
      console.log(e);
      return null;
    }
  };

const LIMIT = 4

const Portfolio = () => {

    const [portfolio, setPortfolio] = useState<any>();

    const [tabData, setTabData] = useState<any>();

    useEffect(() => {
        (async () => {
            const portfolio = await fetchPortfolio({limit: LIMIT});
            setPortfolio(portfolio);
            const data = await getTabsPortfolio();
            setTabData(data)
        })()
    }, []);

    const handleSort = async (id: string) => {
        const portfolio = await fetchPortfolio({category_id: id});
        setPortfolio(portfolio);
    }
    const handleMore = async () => {
        const url = new URL(portfolio?.next)
        const res = await fetchPortfolio({limit: LIMIT, offset: url.searchParams.get('offset')});
        const prev = {...portfolio}.results;
        const newData = {
            ...res,
            results: [
                ...prev,
                ...res?.results,
            ]
        }
        setPortfolio(newData)
        
       
    }
    return(
        <>
            <Container>
                <div className={'portfolio_wrapper'}>
                    <p className="porfolioBlockTitle">Портфолио</p>
                    <p className="porfolioBlockDesc">Ведущая компания на рынке сувенирной продукции, Ваш партнер, консультант и помощник в сфере брендированных подарков и рекламной продукции. Мы – фанаты сувенирной индустрии! Наши светлые головы работают на Ваш бизнес. Мы – созидатели. Творим от создания концепта до полного воплощения проекта в жизнь. Мы – производители. Произведем то, чего еще нет в природе. А еще, мы – немножечко волшебники. Наша работа – создавать красоту, хорошее настроение и классные подарки!</p>
                    <div className={'portfolio_block_tab'}>
                        {tabData?.map((item: any) => (
                            <div onClick={()=>handleSort(item?.id)} className={'portfolio_item_tab'} key={item?.id}>{item?.name}</div>
                        ))}
                    </div>
                    <div className="portfolioBlock">
                        {portfolio && portfolio.results.map((item: any)=> (
                            <PortfolioItem id={item?.id} img={item.photo} title={item.description}/>
                        ))}
                        
                    </div>
                    {
                        portfolio?.next 
                        &&
                        <div onClick={handleMore} className={'portfolio_getMore'}>
                            Посмотреть еще
                        </div>
                    }
                </div>
            </Container>
        </>
    )
}

export default Portfolio;